#logo {
  height: 9vh;
}

#display-time {
  font-size: 2vw;
}

#title {
  text-align: center;
  flex-grow: 1;
  font-size: 3vw;
}

.Tweets {
  margin-top: 8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40vw;
}

.Tweet {
  width: 70%;
}

.Tweet div {
  margin: 5vh 0;
}

#mobile-nav {
  width: 100vw;
  background-color: rgba(47, 66, 172, 0.911);
  transition: all 0.5s ease;
  position: fixed;
  color: white;
  z-index: 1;
}

.wordCloud {
  margin-left: 8vw;
}

#mobile-nav-unclicked {
  display: none;
}

/* wordcloud and paragraph */
.AppBody-right {
  margin: 0 auto;
  margin-top: 8vh;
  display: flex;
  flex-direction: column;
  width: 55vw;
  border-left: 1px solid black;
}

/* app body left and right */
#home-content {
  margin-top: 8vh;
  display: flex;
  flex-direction: row;
}

.main-par {
  color: black;
  margin: 10vh 5vh;
  text-indent: 5vh;
  line-height: 1.8;
}

.par-title {
  text-align: center;
  text-decoration: underline;
  font-size: 3vmax;
}

.footer_container {
  border-top: 2px solid black;
  display: grid;
  justify-content: space-around;
  background-color: #4fb7d6;
  position: relative;
  width: 100%;
  bottom: 0vh;
  margin-top: 5vh;
  font-weight: bold;
  background-image: url("https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.publicdomainpictures.net%2Fpictures%2F80000%2Fvelka%2Fblue-background-waves-13923163600ev.jpg&f=1&nofb=1");
}

.footer_elem_1 {
  border-right: 2px dotted black;
  grid-column-start: 0;
  grid-column-end: 3;
  padding: 0px 10vw;
}

.footer_elem_2 {
  border-right: 2px dotted black;
  grid-column-start: 3;
  grid-column-end: 6;
  padding: 0px 10vw;
}

.footer_elem_3 {
  border-right: 2px dotted black;
  grid-column-start: 6;
  grid-column-end: 9;
  padding: 0px 10vw;
}

.footer_elem_4 {
  grid-column-start: 9;
  grid-column-end: 12;
  padding: 0px 10vw;
}

.about-par {
  font-size: 30px;
  padding: 30px;
  padding-left: 100px;
  padding-right: 100px;
}

.about-title {
  text-align: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 9vh;
  justify-content: space-around;
  align-items: center;
}

.form-group {
  margin: 5vh;
}

.btn-primary {
  width: 5vw;
}

.form-title {
  text-align: center;
}

.form-label {
  margin: 1vw;
}

#breaking-news-chart {
  width: 95vw;
  height: 80vh;
  position: relative;
}

#contact-submit {
  text-align: center;
}

#submit-form-img {
  display: block;
  justify-content: center;
  margin: auto;
  margin-left: 40vw;
  height: 52vh;
}

@media (max-width: 950px) {
  #home-content {
    flex-direction: column;
  }
  #home-content div {
    margin: 0 auto;
  }
  .AppBody-right {
    margin-top: 5vh;
    border: none;
  }
  .AppBody-right .wordCloud {
    position: relative;
    right: 10vw;
    margin-top: 10vh;
  }
  .Tweets {
    position: relative;
    right: 10vw;
  }
  .Tweets .Tweet {
    width: 80vw;
  }
  .wordCloud {
    width: 80vw;
  }
}

@media (max-width: 475px) {
  #display-time {
    display: none;
  }
}
